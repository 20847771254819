import React from 'react';
import '../styles/Footer.css';

const ContactSection = () => (
  <div id="#kapcsolat"  style={{ maxWidth: '800px', margin: '0 auto', padding: '20px', textAlign: 'center', color: 'white', backgroundColor: 'black', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
    <h2 style={{ color: '#FEB809', marginBottom: '20px' }}>Kapcsolat</h2>
    <p style={{ fontSize: '18px', margin: '5px 0' }}>
      <strong>Telefon:</strong> +36 70 555 9052
    </p>
    <p style={{ fontSize: '18px', margin: '5px 0' }}>
      <strong>Email:</strong> <a href="mailto:service@slimstore.hu" style={{ color: '#FEB809', textDecoration: 'none' }}>service@slimstore.hu</a>
    </p>
    <p style={{ fontSize: '18px', margin: '5px 0' }}>
      <strong>Cím:</strong> 1061 Budapest, Andrássy út 3.
    </p>
    <p style={{ fontSize: '18px', margin: '5px 0' }}>
      <strong>Nyitvatartás:</strong> H-P: 10:00 - 18:00
    </p>
    <div style={{ border: '1px solid #444', borderRadius: '8px', overflow: 'hidden', marginTop: '20px' }}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10796.28892714676!2d19.056073839560604!3d47.4979132929364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741dc3b3c3c379d%3A0x78c1b2f8c5a6feca!2sAndr%C3%A1ssy%20%C3%BAt%2C%20Budapest%2C%20Hungary!5e0!3m2!1sen!2sus!4v1598866728475!5m2!1sen!2sus"
        width="600"
        title='map'
        height="450"
        style={{ border: 0, width: '100%', height: '450px' }}
        allowFullScreen={false}
        loading="lazy"
      ></iframe>
    </div>
  </div>
);
const Footer: React.FC = () => {
  return (
    <div style={{textAlign: "center", background: "black", color: "white", padding: "10px"}}>
    <footer className="footer">
      <div className="contact-info">
        <ContactSection/>
      </div>
      <div className="quick-links">
      <a className='quicka' href="/">Főoldal</a>
        <a className='quicka' href="/idopontfoglalas">Időpontfoglalás</a>
        
        <a className='quicka' href="/szerviz">Árak</a>
        <a className='quicka' href="/kapcsolat">Kapcsolat</a>

      </div>
      
    </footer>
    <p>&copy; 2024 Slim Service. Minden jog fenntartva.</p>
    </div>
  );
}

export default Footer;
