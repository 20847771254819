import React, { ChangeEvent, useEffect, useState } from 'react';
import axios from 'axios';
import '../../styles/Admin.css';
import { FaInfoCircle, FaEdit, FaTrash } from 'react-icons/fa';
import Modal from  'react-modal';
import 'react-calendar/dist/Calendar.css';
import Select from 'react-select';

interface Booking {
  id: string;
  user_name: string;
  user_phone: string;
  user_email: string;
  user_address: string;
  delivery: string;
  imei: string;
  manufacturer: string;
  model: string;
  warranty: string;
  status: string;
  service_types: string[];
  status_changes?: StatusChange[];
  picture1: string | null;
  picture2: string | null;
  appointment_date: string | null;
}
interface Manufacturer {
  manufacturer_id: string;
  name: string;
}

interface Device {
  manufacturer_id: string;
  model_id: string;
  name: string;
}
interface RepairType {
  service_type_id: string;
  name: string;
}

interface UserData {
  name: string;
  phone: string;
  email: string;
  imei: string;
  warranty: boolean;
  status: string;
}
interface Prices {
  beszer: number;
  bevetel: number;
  paymentType: string;
}
interface StatusChange {
  id: string;
  booking_id: string;
  status: string;
  change_date: string;
  picture1: string | null;
  picture2: string | null;
  comment: string;
}
const Service: React.FC = () => {
  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([]);
  const [devices, setDevices] = useState<Device[]>([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState<{ value: string, label: string } | null>(null);
  const [selectedModel, setSelectedModels] = useState<{ manufacturer_id: string; model_id: string; name: string; } | null>(null);
  const [userData, setUserData] = useState<UserData>({
    name: '',
    phone: '',
    email: '',
    imei: '',
    warranty: false,
    status: 'under_repair'
  });
  const [prices, setPrices] = useState<Prices>({
    beszer: 0,
    bevetel: 0,
    paymentType: 'cash'
  });
  const [addressForm, setaddressForm] = useState({
    zip: '',
    city: '',
    street: ''
  });
  const [filteredDevices, setFilteredDevices] = useState<Device[]>([]);
  const [selectedRepair, setSelectedRepair] = useState<RepairType>();
  const [repairOptions, setRepairType] = useState<RepairType[]>([]);
    const [bookings, setBookings] = useState<Booking[]>([]);
    const [filteredBookings, setFilteredBookings] = useState<Booking[]>([]);
    const [filteredWarranty, setFilteredWarranty] = useState<number>(2);
    const [selectedBooking, setSelectedBooking] = useState<Booking | null>(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [comment, setComment] = useState('');
    const [newmodalIsOpen, setNewModalIsOpen] = useState(false);
  useEffect(() => {
    getRepairTypes();
    // Fetch manufacturers
    fetch('https://szerviz.slimstore.hu/api/manufacturers')
      .then(response => response.json())
      .then(data => setManufacturers(data))
      .catch(error => console.error('Error fetching manufacturers:', error));

    // Fetch devices
    fetch('https://szerviz.slimstore.hu/api/models')
      .then(response => response.json())
      .then(data => setDevices(data))
      .catch(error => console.error('Error fetching devices:', error));
    axios.get('https://szerviz.slimstore.hu/api/bookings')
      .then(response => {
        setBookings(response.data);
        setFilteredBookings(response.data.filter((booking: Booking) => booking.status !=="archived"));
      })
      .catch(error => {
        console.error('There was an error fetching the bookings!', error);
      });
  }, []);
  const openModal = (booking: Booking) => {
    setSelectedBooking(booking);
    axios.get('https://leltar.slimstore.hu/service.php?id='+booking.id)
      .then(response => {
        if(response.data.length > 0) {

        setPrices({
          beszer: response.data[0].purchase_price,
          bevetel: response.data[0].income,
          paymentType: response.data[0].payment_type
        });
      }
      })
    setModalIsOpen(true);
  };

  const openNewModal = () => {
    
    setNewModalIsOpen(true);
    
  };
  const closeNewModal = () => {
    setNewModalIsOpen(false);
    setPrices({
      beszer: 0,
      bevetel: 0,
      paymentType: 'cash'
    });
    setUserData({
      name: '',
      phone: '',
      email: '',
      imei: '',
      warranty: false,
      status: ''
    });
    setaddressForm({
      zip: '',
      city: '',
      street: ''
    });
  };
  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedBooking(null);
    setPrices({
      beszer: 0,
      bevetel: 0,
      paymentType: 'cash'
    });
  };
  const handleManufacturerChange = (selectedOption: any) => {
    setSelectedModels(null);
    setSelectedManufacturer(selectedOption);
    const filtered = devices.filter(device => device.manufacturer_id === selectedOption.value);
    setFilteredDevices(filtered);
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, files } = e.target as HTMLInputElement;
    if (selectedBooking) {
      if (files) {
        setSelectedBooking({
          ...selectedBooking,
          [name]: files[0]
        });
      } else {
        setSelectedBooking({
          ...selectedBooking,
          [name]: value
        });
      }
    }
  };
  const getRepairTypes = async () => {
    const serviceTypesResponse = await axios.get('https://szerviz.slimstore.hu/api/servicetypes');
    setRepairType(serviceTypesResponse.data);
  }

  const handleNewInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    
    const { name, value} = e.target as HTMLInputElement;
    if(name ==="status") {
      setUserData({
        ...userData,
        name: value
      });
    } else {
      setUserData({
        ...userData,
        [name]: value
      });
    }
    
  };
  const handlePrices = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value} = e.target as HTMLInputElement;
    setPrices({
      ...prices,
      [name]: value
    });
  };
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const formData = new FormData();
    const fullAddress = `${addressForm.zip}, ${addressForm.city}, ${addressForm.street}`;
    formData.append('manufacturer', selectedManufacturer!.label);
    formData.append('model', selectedModel!.name);
    formData.append('imei', userData.imei);
    formData.append('service_type_ids[]', repairOptions.find(option => option.name === selectedRepair!.name)!.service_type_id);
    formData.append('delivery', '0');
    formData.append('user_name', userData.name);
    formData.append('user_phone', userData.phone);
    formData.append('user_email', userData.email);
    formData.append('user_address', fullAddress);
    formData.append('warranty', userData.warranty ? '1' : '0');
    formData.append('status', userData.status ===undefined ? "under_repair" : userData.status);
    formData.append('comment', comment);
    
    if (userData.status ==="ready") {
      const formData2 = new FormData();
      formData2.append('repair_type', selectedRepair!.name);
      formData2.append('purchase_price', prices.beszer.toString());
      formData2.append('income', prices.bevetel.toString());
      formData2.append('payment_type', prices.paymentType);

      axios.post('https://leltar.slimstore.hu/service.php', formData2)
    }
      axios.post('https://szerviz.slimstore.hu/api/bookings', formData)
      .then(response => {
        window.location.reload();
      })
      .catch(error => {
        alert('Hiba történt az időpontfoglalás során. Kérjük, próbálja újra később!');
      });
    
    
  }
  const saveChanges = () => {
    if (selectedBooking) {
      const formData = new FormData();
      formData.append('comment', comment);
      // Append all booking data except files
      Object.keys(selectedBooking).forEach(key => {
        if (key !== 'picture1' && key !== 'picture2') {
          
          if (key === 'service_types') {
            formData.append("service_type", (selectedBooking as any)["service_types"].split(":")[0])
          } else {
            formData.append(key, (selectedBooking as any)[key]);
          }
          
        }
        
      });
  
      // Append files if they exist
      if (selectedBooking.picture1) {
        formData.append('picture1', selectedBooking.picture1);
      }
      if (selectedBooking.picture2) {
        formData.append('picture2', selectedBooking.picture2);
      }
        const token = localStorage.getItem('token');
        if( selectedBooking.status ==="ready" || selectedBooking.status ==="archived") {
            const formData2 = new FormData();
            formData2.append('repair_type', (selectedBooking as any)["service_types"].split(":")[1]);
            formData2.append('purchase_price', prices.beszer.toString());
            formData2.append('income', prices.bevetel.toString());
            formData2.append('payment_type', prices.paymentType);
            formData2.append('service_id', selectedBooking.id);
            axios.post('https://leltar.slimstore.hu/service.php', formData2)
          

        }

      axios.post(`https://szerviz.slimstore.hu/api/bookingsedit`, formData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      } )
        .then(response => {
           window.location.reload();
        })
        .catch(error => {
          console.error('There was an error updating the booking!', error);
        });
    }
  }

  const handleArchive = (booking: Booking) => {
    if(window.confirm('Biztosan archiválja a foglalást?')) {
    
    const formData = new FormData();
    const token = localStorage.getItem('token');

    Object.keys(booking).forEach(key => {
      if (key !== 'picture1' && key !== 'picture2') {
        
        if (key === 'service_types') {
          formData.append("service_type", (booking as any)["service_types"].split(":")[0])
        } else if (key === 'status') {
          formData.append(key, 'archived');
        } else {
          formData.append(key, (booking as any)[key]);
        }
        
      }
      
    });
    axios.post(`https://szerviz.slimstore.hu/api/bookingsedit`, formData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    } )
      .then(response => {
         window.location.reload();
      })
      .catch(error => {
        console.error('There was an error updating the booking!', error);
      });
    }
  }

    const getStatusLocalizedString = (status: string) => {
      switch (status) {
        case 'under_review':
          return 'Ellenőrzés alatt';
        case 'accepted':
          return 'Garanciális javítás elfogadva';
        case 'rejected':
          return 'Garanciális javítás elutasítva';
        case 'awaiting_arrival':
          return 'Várakozás a termék érkezésére';
        case 'under_repair':
          return 'Javítás alatt';
        case 'ready':
          return 'Kész';
        case 'archived':
          return 'Archivált';
        default:
          return 'Ismeretlen';
      }
    }
    const handleComment = (e: ChangeEvent<HTMLInputElement>) => {
      setComment(e.target.value);
    }
    const filterWarranty = (warranty: string) => {
      if (warranty === "all") {
        setFilteredBookings(bookings.filter(booking => booking.status !=="archived"));
        setFilteredWarranty(2);
        return bookings.filter(booking => booking.status !=="archived");
      } else if(warranty === "archived") {
        
        setFilteredBookings(bookings.filter(booking => booking.status === "archived"));
        setFilteredWarranty(3);
        return bookings.filter(booking => booking.status === "archived");
      } else {
        setFilteredBookings(bookings.filter(booking => booking.status !=="archived").filter(booking => booking.warranty === warranty));
        setFilteredWarranty(parseInt(warranty));
        return bookings.filter(booking => booking.status !=="archived").filter(booking => booking.warranty === warranty);
      }
    }
    const handleAdressChange = (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      if (name ==='zip' && value.length ===4) {
        axios.get(`https://hur.webmania.cc/zips/${value}.json`)
          .then(response => {
              if (response.data.zips.length > 0) {
                setaddressForm(prevState => ({
                  ...prevState,
                  city: response.data.zips[0].name
                }))
              } else {
                window.alert('Nem található ilyen irányítószám!')
              }
              
              
          })
          .catch(error => {
            console.error('Error fetching city data:', error);
          });
      }
      setaddressForm(prevState => ({
        ...prevState,
        [name]: value
      }));
    };
  return (
    <div>
      <button className='adminButton' onClick={() => openNewModal()}>Új szerviz felvitele</button>
      
      <div>
        <h2>Szerviz</h2>
      <button className='adminButton' value="all" style={filteredWarranty === 2 ? {backgroundColor: "black", color: "white"} : {backgroundColor: "white", color: "black"}} onClick={() => filterWarranty("all")}>Összes</button>
      <button className='adminButton' value="1" style={filteredWarranty === 1 ? {backgroundColor: "black", color: "white"} : {backgroundColor: "white", color: "black"}} onClick={() => filterWarranty("1")}>Garanciális</button>
      <button className='adminButton' value="0" style={filteredWarranty === 0 ? {backgroundColor: "black", color: "white"} : {backgroundColor: "white", color: "black"}} onClick={() => filterWarranty("0")}>Nem garanciális</button>
      <button className='adminButton' value="0" style={filteredWarranty === 3 ? {backgroundColor: "black", color: "white"} : {backgroundColor: "white", color: "black"}} onClick={() => filterWarranty("archived")}>Archivált</button>
        <table className="styled-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Név</th>
            <th>Futár</th>
            <th>Érkezés</th>
            <th>IMEI</th>
            <th>Gyártó</th>
            <th>Model</th>
            <th>Garanciális</th>
            <th>Státusz</th>
            <th>Típus</th>
            <th>Műveletek</th>
          </tr>
        </thead>
        <tbody>
          {filteredBookings.map(booking => (
            <tr key={booking.id} onClick={() => openModal(booking)}>
              <td>{booking.id}</td>
              <td><div className="tooltip">
                  {booking.user_name} <FaInfoCircle className="info-icon" />
                  <span className="tooltiptext">
                    <p>Telefon: {booking.user_phone}</p>
                    <p>Email: {booking.user_email}</p>
                    <p>Cím: {booking.user_address}</p>
                  </span>
                </div></td>
              <td>{booking.delivery ==="0" ? "Nem" : "Igen"}</td>
              <td>{booking.appointment_date !=="" ? booking.appointment_date : "-"}</td>
              <td>{booking.imei}</td>
              <td>{booking.manufacturer}</td>
              <td>{booking.model}</td>
              <td>{booking.warranty ==="0" ? "Nem" : "Igen"}</td>
              <td>{getStatusLocalizedString(booking.status)}</td>
              <td>{booking.service_types}</td>
              <td><FaEdit className="edit-icon" onClick={() => openModal(booking)} />  {booking.status !== "archived" && <FaTrash className="edit-icon" onClick={() => handleArchive(booking)} />}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    {selectedBooking && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Edit Booking"
          className="modal"
          overlayClassName="modal-overlay"
        >
          <h2>Foglalás #{selectedBooking.id}</h2>
          <form>
          
            <label>
              Név:
              <input
                type="text"
                name="user_name"
                value={selectedBooking.user_name}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Telefonszám:
              <input
                type="text"
                name="user_phone"
                value={selectedBooking.user_phone}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Email:
              <input
                type="text"
                name="user_email"
                value={selectedBooking.user_email}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Cím:
              <input
                type="text"
                name="user_address"
                value={selectedBooking.user_address}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Státusz:
              <select
                name="status"
                value={selectedBooking.status}
                onChange={handleInputChange}
              >
                <option value="under_review">Garanciális javítás elbírálás alatt</option>
                <option value="accepted">Garanciális javítás elfogadva</option>
                <option value="rejected">Garanciális javítás elutasítva</option>
                <option value="awaiting_arrival">Várakozás</option>
                <option value="under_repair">Folyamatban</option>
                <option value="ready">Befejezett</option>
                <option value="archived">Archivált</option>
              </select>
            </label>
            <label style={{ fontSize: '13px' }}>Megjegyzés</label>
            <input type="text" name="comment" value={comment} onChange={handleComment} />
            { (selectedBooking.status === "ready" || selectedBooking.status === "archived") &&  (<label style={{ fontSize: '13px' }}>
       Beszerzési ár:
        <input type="number" name="beszer" value={prices.beszer} onChange={handlePrices} required />
      </label>)}
      { (selectedBooking.status === "ready" || selectedBooking.status === "archived") &&  (<label style={{ fontSize: '13px' }}>
       Eladási ár:
        <input type="number" name="bevetel" value={prices.bevetel} onChange={handlePrices} required />
      </label>)}
      { (selectedBooking.status === "ready" || selectedBooking.status === "archived") &&  (<label style={{ fontSize: '13px' }}>
       Fizetési mód:
        <select
                name="paymentType"
                value={prices.paymentType}
                onChange={handlePrices}
              >
                <option value="cash">Készpénz</option>
                <option value="creditcard">Bankkártya</option>
                <option value="card">Kártya</option>
              </select>
      </label>)}
            <label>
                  1. folyamatkép (nem kötelező):
                  <input
                    type="file"
                    name="picture1"
                    onChange={handleInputChange}
                  />
                </label>
                <label>
                2. folyamatkép (nem kötelező):
                  <input
                    type="file"
                    name="picture2"
                    onChange={handleInputChange}
                  />
                </label>
                          {selectedBooking.status_changes && selectedBooking.status_changes.length > 0 && (
                                      <div>
                                        <br></br>
                                        <hr></hr>
                                              <p className='statusrow'>Státuszváltozások</p>
                                                      {selectedBooking.status_changes.map(change => (
                                                          <p className='statusrow'>
                                                              {change.change_date} - {change.status} - {change.comment}
                                                              {change.picture1 ? (
                        <a style={{textDecoration: "none"}} href={"https://szerviz.slimstore.hu/"+change.picture1} target="_blank" rel="noopener noreferrer">
                          <p className='picture' >Kép 1</p>
                        </a>
                      ) : ''}
                      {change.picture2 ? (
                        <a  style={{textDecoration: "none"}} href={"https://szerviz.slimstore.hu/"+change.picture2} target="_blank" rel="noopener noreferrer">
                          <p className='picture'>Kép 2</p>
                        </a>
                      ) : ''}
                    </p>
                                                      ))}
                                      </div>
                                  )}
                          <button type="button" onClick={saveChanges}>Mentés</button>
                          <button type="button" onClick={closeModal}>Bezárás</button>
          </form>
          
        </Modal>
      )}
      <Modal
          isOpen={newmodalIsOpen}
          onRequestClose={closeNewModal}
          contentLabel="Add Booking"
          className="modal"
          overlayClassName="modal-overlay"
        >
          <h2>Foglalás felvitele</h2>
          <form>
          <Select
        options={manufacturers.map(manufacturer => ({
          value: manufacturer.manufacturer_id,
          label: manufacturer.name,
        }))}
        onChange={handleManufacturerChange}
        placeholder="Gyártó"

        isSearchable
      />
      <br></br>
          <Select
          options={filteredDevices.map(device => ({
            value: device.model_id,
            label: device.name,
            manufacturer_id: device.manufacturer_id,
            name: device.name,
          }))}
          onChange={(selectedOption: any) => {setSelectedModels(selectedOption);}}
          placeholder="Modell"
          isSearchable
        /><br></br><Select
        options={repairOptions.map(device => ({
          label: device.name,
          service_type_id: device.service_type_id,
          name: device.name,
        }))}
        onChange={(selectedOption: any) => {setSelectedRepair(selectedOption);}}
        placeholder="Szerviz típus"
        isSearchable
      /><label>
        Imei:
        <input
          type="text"
          name="imei"
          value={userData.imei}
          onChange={handleNewInputChange}
        />
      </label>
            <label>
              Név:
              <input
                type="text"
                name="name"
                value={userData.name}
                onChange={handleNewInputChange}
              />
            </label>
            <label>
              Telefonszám:
              <input
                type="text"
                name="phone"
                value={userData.phone}
                onChange={handleNewInputChange}
              />
            </label>
            <label>
              Email:
              <input
                type="text"
                name="email"
                value={userData.email}
                onChange={handleNewInputChange}
              />
            </label>
            <label style={{ fontSize: '13px' }}>
        Irányítószám:
        <input type="text" name="zip" value={addressForm.zip} onChange={handleAdressChange} required />
      </label>
      <label style={{ fontSize: '13px' }}>
        Város:
        <input type="text" name="city" value={addressForm.city} readOnly />
      </label>
      <label style={{ fontSize: '13px' }}>
        Utca, házszám, emelet, ajtószám:
        <input type="text" name="street" value={addressForm.street} onChange={handleAdressChange} required />
      </label>
            <label>
              Státusz:
              <select
                name="status"
                value={userData.status}
                onChange={handleNewInputChange}
              >
                <option value="under_repair">Folyamatban</option>
                <option value="ready">Befejezett</option>
              </select>
            </label>
            <label style={{ fontSize: '13px' }}>Megjegyzés</label>
            <input type="text" name="comment" value={comment} onChange={handleComment} />
          { userData.status ==="ready" &&  (<label style={{ fontSize: '13px' }}>
       Beszerzési ár:
        <input type="number" name="beszer" value={prices.beszer} onChange={handlePrices} required />
      </label>)}
      { userData.status ==="ready" &&  (<label style={{ fontSize: '13px' }}>
       Eladási ár:
        <input type="number" name="bevetel" value={prices.bevetel} onChange={handlePrices} required />
      </label>)}
      { userData.status ==="ready" &&  (<label style={{ fontSize: '13px' }}>
       Fizetési mód:
        <select
                name="paymentType"
                value={prices.paymentType}
                onChange={handlePrices}
              >
                <option value="cash">Készpénz</option>
                <option value="creditcard">Bankkártya</option>
                <option value="card">Kártya</option>
              </select>
      </label>)}
                          <button type="button" onClick={handleSubmit}>Mentés</button>
                          <button type="button" onClick={closeModal}>Bezárás</button>
          </form>
          
        </Modal>
  </div>
  );
}

export default Service;
