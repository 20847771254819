import React from 'react';
import '../styles/Contact.css';

const Contact: React.FC = () => {
  return (
    <div className="home">
      <section className="intro">
        <h1>Kapcsolat</h1>
        <p></p>
      </section>
      
      
    <div style={{ width: "70%", margin: "auto", textAlign: "center", padding: "25px"}}>
    <img src='https://slimstore.hu/wp-content/uploads/2024/01/IMG_2120-scaled.webp' alt='Slim Store' style={{width: "80%"}}></img>
    <p style={{ fontSize: '18px', margin: '5px 0' }}>
      <strong>Telefon:</strong> +36 70 555 9052
    </p>
    <p style={{ fontSize: '18px', margin: '5px 0' }}>
      <strong>Email:</strong> <a href="mailto:service@slimstore.hu" style={{ color: '#FEB809', textDecoration: 'none' }}>service@slimstore.hu</a>
    </p>
    <p style={{ fontSize: '18px', margin: '5px 0' }}>
      <strong>Cím:</strong> 1061 Budapest, Andrássy út 3.
    </p>
    <p style={{ fontSize: '18px', margin: '5px 0' }}>
      <strong>Nyitvatartás:</strong> H-P: 10:00 - 18:00
    </p>
  </div>
  
    </div>
  );
}

export default Contact;
