import React, { useEffect, useState } from 'react';
import '../../styles/Admin.css';
import Select from 'react-select';
import axios from 'axios';

interface Manufacturer {
    manufacturer_id: string;
    name: string;
  }
  
  interface Device {
    manufacturer_id: string;
    model_id: string;
    name: string;
    value: string;
  }
  interface RepairType {
    id: number;
    service_type_id: string;
    name: string;
    time: number;
    price: number;
    desc: string;
  }
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      minHeight: '60px',
      minWidth: '300px',
      borderRadius: '35px',
      fontSize: '16px',
      marginBottom: '20px',
    }),
    menu: (provided: any) => ({
      ...provided,
      borderRadius: '20px', // Border radius for the menu
    }),
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: '200px', // Adjust as needed
      scrollbarWidth: 'none', // For Firefox
      msOverflowStyle: 'none', // For Internet Explorer and Edge
      '&::-webkit-scrollbar': {
        display: 'none', // For Chrome, Safari, and Opera
      },
    }),
    option: (provided: any) => ({
      ...provided,
      padding: '15px',
      color: 'black',
      borderRadius: '20px',
    }),
    placeholder: (provided: any) => ({
      ...provided,
      fontSize: '16px',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      fontSize: '16px',
    }),
  };
const PriceList: React.FC = () => {
    


  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([]);
  const [devices, setDevices] = useState<Device[]>([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState<{ value: string, label: string } | null>(null);
  const [selectedModel, setSelectedModels] = useState<Device|null>(null);
  const [filteredDevices, setFilteredDevices] = useState<Device[]>([]);
  const [repairOptions, setRepairType] = useState<RepairType[]>([]);
  useEffect(() => {
    fetch('https://szerviz.slimstore.hu/api/manufacturers')
      .then(response => response.json())
      .then(data => setManufacturers(data))
      .catch(error => console.error('Error fetching manufacturers:', error));

    // Fetch devices
    fetch('https://szerviz.slimstore.hu/api/models')
      .then(response => response.json())
      .then(data => setDevices(data))
      .catch(error => console.error('Error fetching devices:', error));
  }
  , []);
  const getPrices = async (device: any) => {
    
    const serviceTypesResponse = await axios.get('https://szerviz.slimstore.hu/api/servicetypes');
    const serviceTypes: RepairType[] = serviceTypesResponse.data.map((item: any) => ({
      service_type_id: item.service_type_id,
      name: item.name,
      time: item.time || 0,
      price: null, 
      desc: item.desc
    }));
    setSelectedModels(device);
    const repairpriceResponse = await axios.get('https://szerviz.slimstore.hu/api/serviceprices?model_id=' + device.value);
    const updatedRepairOptions = serviceTypes.map(option => {
      const matchingPrice = repairpriceResponse.data.find((price: any) => price.service_type_id === option.service_type_id);
      if (matchingPrice) {
        return { ...option, price: parseFloat(matchingPrice.price), id: matchingPrice.service_price_id };
      }
      return option;
    });
    setRepairType(updatedRepairOptions);
    console.log(updatedRepairOptions);
  }
  const handleManufacturerChange = (selectedOption: any) => {
    
    setSelectedManufacturer(selectedOption);
    setSelectedModels(null);
    const filtered = devices.filter(device => device.manufacturer_id === selectedOption.value);
    setFilteredDevices(filtered);
  };
  const handlePriceChange = (index: number, value: number) => {
    const updatedServices = [...repairOptions];
    updatedServices[index].price = value;
    setRepairType(updatedServices);
  };
  const handleSave = () => {
    const updatedNewServices = repairOptions.map((service) => ({
      id: service.id,
      model_id: selectedModel?.value,
      service_type_id: service.service_type_id,
      price: service.price,
    }));
    const token = localStorage.getItem('token');
    axios.put('https://szerviz.slimstore.hu/api/serviceprices', updatedNewServices, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      } ).then(response => {
        window.alert('Árak mentve');
        setFilteredDevices([]);
        setSelectedModels(null);
        setSelectedManufacturer(null);
        setRepairType([]);
      });
  };
  return (
    <div>
       <Select
        options={manufacturers.map(manufacturer => ({
          value: manufacturer.manufacturer_id,
          label: manufacturer.name,
        }))}
        onChange={handleManufacturerChange}
        placeholder="Válassz gyártót"
        isSearchable
        styles={customStyles}
      />
      
      {selectedManufacturer && (
        <Select
          options={filteredDevices.map(device => ({
            value: device.model_id,
            label: device.name,
            manufacturer_id: device.manufacturer_id,
            name: device.name,

          }))}
          onChange={(selectedOption: any) => {getPrices(selectedOption);}}
          placeholder="Válassz típust"
          isSearchable
          styles={customStyles}
        />
      )}
      {repairOptions.map((service, index) => (
        <div className='prices' key={service.service_type_id}>
          <label style={{marginLeft:"20px", paddingRight: "10px"}}>{service.name}</label>
          <input
            type="number"
            value={service.price ?? ''}
            onChange={(e) => handlePriceChange(index, Number(e.target.value))}
          /><label style={{marginLeft:"10px", marginRight: "10px"}}>Ft</label>
        </div>
      ))}
<button onClick={handleSave}>Mentés</button>
  </div>
  );
}

export default PriceList;
