import React, { useState, useEffect, ChangeEvent } from 'react';
import '../styles/Booking.css';
import '../styles/Switcher.css';
import Select,{ SingleValue } from 'react-select';
import axios from 'axios';
import Lottie from 'lottie-react';
import { addDays, format, isWeekend, setHours, setMinutes, eachDayOfInterval, eachHourOfInterval } from 'date-fns';
import { FaInfoCircle } from 'react-icons/fa';

interface Device {
  manufacturer_id: string;
  model_id: string;
  name: string;
}
interface RepairType {
  service_type_id: string;
  name: string;
}
interface AppointmentOption {
  value: Date;
  label: string;
}

interface UserData {
  name: string;
  phone: string;
  email: string;
  address: string;
  picture1: string | null;
  picture2: string | null;
}


const customStyles = {
  control: (provided: any) => ({
    ...provided,
    minHeight: '60px',
    minWidth: '300px',
    borderRadius: '35px',
    fontSize: '16px',
    marginBottom: '20px',
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: '20px', // Border radius for the menu
  }),
  menuList: (provided: any) => ({
    ...provided,
    maxHeight: '200px', // Adjust as needed
    scrollbarWidth: 'none', // For Firefox
    msOverflowStyle: 'none', // For Internet Explorer and Edge
    '&::-webkit-scrollbar': {
      display: 'none', // For Chrome, Safari, and Opera
    },
  }),
  option: (provided: any) => ({
    ...provided,
    padding: '15px',
    color: 'black',
    borderRadius: '20px',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontSize: '16px',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontSize: '16px',
  }),
};

const Booking: React.FC = () => {
  const [devices, setDevices] = useState<Device[]>([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState<{ value: string, label: string } | null>(null);
  const [selectedModel, setSelectedModels] = useState<{ manufacturer_id: string; model_id: string; name: string; } | null>(null);
  const [availableAppointments, setAvailableAppointments] = useState<AppointmentOption[]>([]);
  const [userData, setUserData] = useState<UserData>({
    name: '',
    phone: '',
    email: '',
    address: '',
    picture1: null,
    picture2: null,
  });
  const [addressForm, setaddressForm] = useState({
    zip: '',
    city: '',
    street: ''
  });
  const [filteredDevices, setFilteredDevices] = useState<Device[]>([]);
  const [imei, setImei] = useState('');
  const [warranty, setWarranty] = useState<String[]>([]);
  const [selectedRepair, setSelectedRepair] = useState<string>('');
  const [repairOptions, setRepairType] = useState<RepairType[]>([]);
  const [deliveryMethod, setDeliveryMethod] = useState<string>('');
  const [appointment, setAppointment] = useState<SingleValue<AppointmentOption>>(null);
  const [published, setPublished] = useState(false);
  const [warrantyBool, setWarrantyBool] = useState<boolean | null>(null);

  const handleDeliveryMethodChange = (method: string) => {
    setDeliveryMethod(method);
    setAppointment(null); // Reset appointment when delivery method changes
  };

  useEffect(() => {
      generateAvailableAppointments();
    
    // Fetch manufacturers
    setSelectedManufacturer({ value: '1', label: 'Apple' });
    // Fetch devices
    fetch('https://szerviz.slimstore.hu/api/models')
      .then(response => response.json())
      .then(data => setDevices(data))
      .catch(error => console.error('Error fetching devices:', error));
      const checkWarranty = async () => {
        if (imei.length > 14 && warrantyBool) {
          try {
            const response = await axios.get(`https://leltar.slimstore.hu/garancia.php?imei=${imei}`);
            if(response.data.length === 0) {

              setWarranty([]);
              const serviceTypesResponse = await axios.get('https://szerviz.slimstore.hu/api/servicetypes');
              setRepairType(serviceTypesResponse.data);
              setSelectedRepair('');
              window.alert("Nem található garancia az IMEI számhoz!");
              setImei('');
              return;
            }
            const warrantyTypes = response.data[0]["garanciaType"].split(',');
        
            let warrantyfront = 0;
            let warrantyback = 0;
        
            warrantyTypes.forEach((item: string) => {
              if (item === "Előlapi garancia" || item === " Előlapi garancia") {
                warrantyfront = 1;
              } else if (item === " Hátlapi garancia" || item === "Hátlapi garancia") {
                warrantyback = 1;
              }
            });
            setSelectedRepair('');
            setWarranty(warrantyTypes);
            const serviceTypesResponse = await axios.get('https://szerviz.slimstore.hu/api/servicetypes?warrantyfront='+warrantyfront+'&warrantyback='+warrantyback);
            setRepairType(serviceTypesResponse.data.filter((option: { name: string; }) => option.name.includes("Garanciális")));
        
          } catch (error) {
            console.error('Error checking warranty', error);
          }
        } else {
          setWarranty([]);
              const serviceTypesResponse = await axios.get('https://szerviz.slimstore.hu/api/servicetypes');
              setRepairType(serviceTypesResponse.data);
        }
      };
      checkWarranty();
  }, [imei, warrantyBool]);
  const generateAvailableAppointments = async () => {
    const now = new Date();
    const minDate = addDays(now, 1); // minimum 1 nappal későbbi időpont
    const maxDate = addDays(now, 30); // maximum 30 nappal későbbi időpont
    const days = eachDayOfInterval({ start: minDate, end: maxDate });
    const appointments: AppointmentOption[] = [];
    const response = await axios.get('https://szerviz.slimstore.hu/api/appointments');
    const bookedAppointments = response.data.map((appointment: { appointment_date: string }) => appointment.appointment_date);
    days.forEach(day => {
      if (!isWeekend(day)) { // csak hétköznapok
        const startOfDay = setHours(setMinutes(day, 0), 10);
        const endOfDay = setHours(setMinutes(day, 0), 17);
        const hours = eachHourOfInterval({ start: startOfDay, end: endOfDay });

        hours.forEach(hour => {
          if (hour > now) { // csak a mostani időpontnál későbbi időpontok
            const formattedHour = format(hour, 'yyyy-MM-dd HH:mm:ss');
            if (!bookedAppointments.includes(formattedHour)) { // csak az elérhető időpontok
              appointments.push({
                value: hour,
                label: format(hour, 'yyyy-MM-dd HH:mm')
              });
            }
          }
        });
      }
    });

    setAvailableAppointments(appointments);
  };
  const handleAdressChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name ==='zip' && value.length ===4) {
      axios.get(`https://hur.webmania.cc/zips/${value}.json`)
        .then(response => {
            if (response.data.zips.length > 0) {
              setaddressForm(prevState => ({
                ...prevState,
                city: response.data.zips[0].name
              }))
            } else {
              window.alert('Nem található ilyen irányítószám!')
            }
            
            
        })
        .catch(error => {
          console.error('Error fetching city data:', error);
        });
    }
    setaddressForm(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, files } = e.target;
    if (files) {
      setUserData({
        ...userData,
        [name]: files[0]
      });
    } else {
      setUserData({
        ...userData,
        [name]: value
      });
    }
  };
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const formData = new FormData();
    const fullAddress = `${addressForm.zip}, ${addressForm.city}, ${addressForm.street}`;
    
    Object.keys(userData).forEach(key => {
      if (key !== 'picture1' && key !== 'picture2') {
        formData.append(key, (userData as any)[key]);
      }
    });
  
    // Append files if they exist
    if (userData.picture1) {
      formData.append('picture1', userData.picture1);
    }
    if (userData.picture2) {
      formData.append('picture2', userData.picture2);
    }
  
    if (warrantyBool) {
      try {
        const response = await axios.post(
          'https://dash.imei.info/api/check/0?API_KEY=b3136589-e6a5-4022-abd4-94bd23b3207f&imei=' + imei
        );
        formData.append('manufacturer', response.data['result']["brand_name"]);
        formData.append('model', response.data['result']["model"]);
      } catch (error) {
        console.error('Error checking warranty:', error);
        alert('Hiba történt a garancia ellenőrzése során. Kérjük, próbálja újra később!');
        return; // Itt érdemes lehet megszakítani a feldolgozást, ha hiba történik
      }
    } else {
      formData.append('manufacturer', selectedManufacturer!.label);
      formData.append('model', selectedModel!.name);
    }
  
    formData.append('imei', imei);
    formData.append('service_type_ids[]', repairOptions.find(option => option.name === selectedRepair)!.service_type_id);
    formData.append('delivery', deliveryMethod === "futárral" ? '1' : '0');
    formData.append('user_name', userData.name);
    formData.append('user_phone', userData.phone);
    formData.append('user_email', userData.email);
    formData.append('user_address', fullAddress);
    formData.append('warranty', repairOptions.find(option => option.name === selectedRepair)?.service_type_id === "0" || repairOptions.find(option => option.name === selectedRepair)?.service_type_id === "1" ? '1' : '0');
    formData.append('status', warranty.length === 0 ? 'awaiting_arrival' : 'under_review');
  
    if (deliveryMethod === 'személyesen') {
      const selectedDate = new Date(appointment!.value);
      const utcDate = new Date(selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000);
      formData.append('appointment', utcDate.toISOString());
    }
  
    if (
      (repairOptions.find(option => option.name === selectedRepair)?.service_type_id === "0" ||
        repairOptions.find(option => option.name === selectedRepair)?.service_type_id === "1") &&
      !userData.picture1 &&
      !userData.picture2
    ) {
      alert('Töltsön fel két képet a készülékről a garanciális elbíráláshoz!');
    } else {
      try {
        await axios.post('https://szerviz.slimstore.hu/api/bookings', formData);
        setPublished(true);
      } catch (error) {
        console.error('Error creating appointment:', error);
        alert('Hiba történt az időpontfoglalás során. Kérjük, próbálja újra később!');
      }
    }
  };

  const handleRepairChange = (repairType: React.SetStateAction<string>) => {
    setSelectedRepair(repairType);
  };
  if (!published) {
  return (
    
    <div className="home">
      <section className="intro">
        <h1>Időpontfoglalás</h1>
        <p>Foglalj időpontot szervizünkbe</p>
      </section>
      <div className="containerBooking">
    <div className="containerbooking">
    <div className="delivery-methods">

        <button 
          className={warrantyBool ? 'active' : ''} 
          onClick={() => {setWarrantyBool(true)
             setImei('')}} 
        >
          Garanciális javítás
        </button>
        <button 
          className={warrantyBool != null && !warrantyBool ? 'active' : ''} 
          onClick={() => {setWarrantyBool(false)
            setImei('')
            setSelectedManufacturer({ value: '1', label: 'Apple' })
          setFilteredDevices(devices.filter(device => device.manufacturer_id === '1'))}}
        >
          Nem garanciális javítás
        </button>
      </div>
      {/* {warrantyBool != null && !warrantyBool && (
        <div>
      <h3>Milyen típusú telefont szeretnél szervizeltetni?</h3>
      <Select
        options={manufacturers.map(manufacturer => ({
          value: manufacturer.manufacturer_id,
          label: manufacturer.name,
        }))}
        onChange={handleManufacturerChange}
        placeholder="Válassz gyártót"
        isSearchable
        styles={customStyles}
      />
      </div>)} */}
      
      {selectedManufacturer && !warrantyBool && warrantyBool != null && (
        <Select
          options={filteredDevices.map(device => ({
            value: device.model_id,
            label: device.name,
            manufacturer_id: device.manufacturer_id,
            name: device.name,
          }))}
          onChange={(selectedOption: any) => {setSelectedModels(selectedOption);}}
          placeholder="Válassz típust"
          isSearchable
          styles={customStyles}
        />
      )}
      {(!selectedManufacturer || !selectedModel) && !warrantyBool && (
        <Lottie animationData={require('../assets/searchphone.json')} />
      )}
      {((selectedModel && selectedManufacturer) || warrantyBool) && (
          <>
          <div>
          <div className="tooltip"><FaInfoCircle className="info-icon" /><span className="tooltiptext">
            Az IMEI (International Mobile Equipment Identity) egy egyedi azonosító szám, amely minden mobiltelefonhoz tartozik. 
            Ezt az azonosítót a hálózati szolgáltatók használják a készülékek azonosítására. 
            Az IMEI számot a telefon hátlapján, az akkumulátor alatt, vagy a telefon beállításaiban találod meg. 
            Ezen kívül, ha beírod a *#06# kódot a telefon hívóképernyőjén, szintén megjelenik az IMEI szám.
        </span></div>
          <h3>Add meg az IMEI számod</h3>
          
            <input
              type="text"
              className='imeiText'
              id="imei"
              placeholder='IMEI szám'
              value={imei}
              onChange={(e) => setImei(e.target.value)}
            />
           {imei.length > 14 && (
            <div className='bordered'>
              <h4>Milyen típusú hibát szeretnél javíttatni:</h4>
            
              { repairOptions.map((option) => (
                
                <button
                className='repairButton'
                style={selectedRepair === option.name ? {backgroundColor: '#FEB809', color: "black"} : {backgroundColor: 'white', color: 'black'}}
                  key={option.service_type_id}
                  onClick={() => handleRepairChange(option.name)}
                >
                  {option.name}
                </button>
              ))}
            
            </div>)}
           
            </div></>)}
            {warrantyBool && selectedRepair && imei.length > 14 && (repairOptions.find(option => option.name === selectedRepair)?.service_type_id ==="0" || repairOptions.find(option => option.name === selectedRepair)?.service_type_id ==="1" ) &&(
            <div className='bordered'>
              <h3>Tölts fel két képet a készülékről</h3>
              <label>
                  1. kép a készülékről:
                  <input
                    type="file"
                    name="picture1"
                    onChange={handleChange}
                    required
                  />
                </label>
                <label>
                2. kép a készülékről:
                  <input
                    type="file"
                    name="picture2"
                    onChange={handleChange}
                    required
                  />
                </label>
            </div>
            )}
            {((selectedModel && selectedManufacturer && selectedRepair &&  imei.length > 14) || (warrantyBool && selectedRepair)) && (
            <div className='bordered'>
            <h3>Hogyan szeretné eljuttatni a készüléket?</h3>
      <div className="delivery-methods">

        <button 
          className={deliveryMethod === 'személyesen' ? 'active' : ''} 
          onClick={() => handleDeliveryMethodChange('személyesen')}
        >
          Személyesen
        </button>
        <button 
          className={deliveryMethod === 'futárral' ? 'active' : ''} 
          onClick={() => handleDeliveryMethodChange('futárral')}
        >
          Futárral
        </button>
      </div>

      {deliveryMethod === 'személyesen' && (
        <div className="appointment-selection">
          <hr></hr>
          <h3>Válassz időpontot</h3>
          <Select
            options={availableAppointments}
            onChange={(selectedOption) => setAppointment(selectedOption)}
            placeholder="Válassz időpontot"
            isSearchable
            styles={{
              control: (provided) => ({
                ...provided,
                minHeight: '60px',
                minWidth: '300px',
                borderRadius: '35px',
                fontSize: '16px',
                marginBottom: '20px',
              }),
            }}
          />
        </div>
      )}

      {((deliveryMethod === 'futárral' && imei.length > 14) || (deliveryMethod === 'személyesen' && appointment && imei.length > 14)) && (
       <div className="personal-info">
       <hr />
       <h3>Adja meg a személyes adatokat</h3>
       <form onSubmit={handleSubmit}>
         <label style={{ fontSize: "13px", textAlign: "center" }}>
           Név:
           <input type="text" name="name" value={userData.name} onChange={handleChange} required />
         </label>
         <label style={{ fontSize: "13px", textAlign: "center" }}>
           Telefonszám:
           <input type="tel" name="phone" value={userData.phone} onChange={handleChange} required />
         </label>
         <label style={{ fontSize: "13px", textAlign: "center" }}>
           E-mail cím:
           <input type="email" name="email" value={userData.email} onChange={handleChange} required />
         </label>
         {deliveryMethod !== 'személyesen' && (<label style={{ fontSize: '13px', textAlign: 'center' }}>
        Irányítószám:
        <input type="text" name="zip" value={addressForm.zip} onChange={handleAdressChange} required />
      </label>)}
      {deliveryMethod !== 'személyesen' && <label style={{ fontSize: '13px', textAlign: 'center' }}>
        Város:
        <input type="text" name="city" value={addressForm.city} readOnly />
      </label>}
      {deliveryMethod !== 'személyesen' && <label style={{ fontSize: '13px', textAlign: 'center' }}>
        Utca, házszám, emelet, ajtószám:
        <input type="text" name="street" value={addressForm.street} onChange={handleAdressChange} required />
      </label>}
         <button type="submit">Küldés</button>
       </form>
     </div>
      )}
            </div> )}
            </div>
    
    </div>
    </div>
  )} else {
    return (
      <div className="home">
        <section className="intro">
          <h1>Időpontfoglalás</h1>
          </section>
          <div className="containerBooking">
    <div className="containerbooking">
    <h3>Az szervizigény benyújtás sikeresen megtörtént</h3>
    <p>A további részletekről e-mailben fogjuk értesíteni!</p>
          <Lottie animationData={require('../assets/success.json')} />
          </div>
          </div>
        
      </div>
    );
  } ;
}

export default Booking;
