import React, {useEffect, useState} from 'react';
import '../styles/Service.css';
import Select from 'react-select';
import axios from 'axios';
import Lottie from 'lottie-react';
import { Link } from 'react-router-dom';
import { FaClock, FaMoneyBill } from 'react-icons/fa';


interface Device {
  manufacturer_id: string;
  model_id: string;
  name: string;
}
interface RepairType {
  service_type_id: string;
  name: string;
  time: number;
  price: number;
  desc: string;
}
const customStyles = {
  control: (provided: any) => ({
    ...provided,
    minHeight: '50px',
    minWidth: '300px',
    borderRadius: '35px',
    fontSize: '16px',
    marginBottom: '20px',
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: '20px', // Border radius for the menu
  }),
  menuList: (provided: any) => ({
    ...provided,
    maxHeight: '200px', // Adjust as needed
    scrollbarWidth: 'none', // For Firefox
    msOverflowStyle: 'none', // For Internet Explorer and Edge
    '&::-webkit-scrollbar': {
      display: 'none', // For Chrome, Safari, and Opera
    },
  }),
  option: (provided: any) => ({
    ...provided,
    padding: '15px',
    color: 'black',
    borderRadius: '20px',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontSize: '16px',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontSize: '16px',
  }),
};
const Service: React.FC = () => {
  const [selectedManufacturer, setSelectedManufacturer] = useState<{ value: string, label: string } | null>(null);
  const [selectedModel, setSelectedModels] = useState<Device|null>(null);
  const [filteredDevices, setFilteredDevices] = useState<Device[]>([]);
  const [repairOptions, setRepairType] = useState<RepairType[]>([]);

  useEffect(() => {
    fetch('https://szerviz.slimstore.hu/api/models')
      .then(response => response.json())
      .then(data => {
        setSelectedManufacturer({ value: '1', label: 'Apple' });
        setFilteredDevices(data.filter((device: { manufacturer_id: string; }) => device.manufacturer_id === '1'));
      })
      .catch(error => console.error('Error fetching devices:', error));
  }
  , []);

  const getPrices = async (device: any) => {
    
    const serviceTypesResponse = await axios.get('https://szerviz.slimstore.hu/api/servicetypes');
    var serviceTypes: RepairType[] = serviceTypesResponse.data.map((item: any) => ({
      service_type_id: item.service_type_id,
      name: item.name,
      time: item.time || 0,
      price: null, 
      desc: item.desc
    }));
    setSelectedModels(device);
    if (parseInt(device.value) < 11) {
      serviceTypes = serviceTypes.filter((item) => item.name !== "Hátlapcsere");
    }
    const repairpriceResponse = await axios.get('https://szerviz.slimstore.hu/api/serviceprices?model_id=' + device.value);
    const updatedRepairOptions = serviceTypes.map(option => {
      const matchingPrice = repairpriceResponse.data.find((price: any) => price.service_type_id === option.service_type_id);
      if (matchingPrice) {
        return { ...option, price: parseFloat(matchingPrice.price) };
      }
      return option;
    });
    setRepairType(updatedRepairOptions);
  }
  const formatPrice = (price: number) => {
    return price.toLocaleString('hu-HU', { style: 'currency', currency: 'HUF' }).replace(/,00/g, '');
  };
  
  return (
    <div className="home">
    <section className="intro">
      <h1>Szerviz árak</h1>
      <p>A Slim Service gyors és megbízható Apple készülékekkel foglalkozó szerviz, amely professzionális javítást kínál minden típusú Apple készülékhez.</p>
    </section>
    <div className='containerBooking'>
  <div className="container2">
  <h3>Milyen típusú készüléket szeretnél szervizeltetni?</h3>
      {selectedManufacturer && (
        <Select
          options={filteredDevices.map(device => ({
            value: device.model_id,
            label: device.name,
            manufacturer_id: device.manufacturer_id,
            name: device.name,
          }))}
          onChange={(selectedOption: any) => {getPrices(selectedOption);}}
          placeholder="Válassz típust"
          isSearchable
          styles={customStyles}
        />
      )}
      {(!selectedManufacturer || !selectedModel) && (
        <Lottie animationData={require('../assets/searchphone.json')} />
      )}
      {selectedModel &&(
        <div>
        <h3>{selectedManufacturer?.label} {selectedModel.name} árak</h3>
        <div className='repair-list'>
          
          {repairOptions.map((repair) => (
        <div key={repair.service_type_id} className="repair-item">
          <h3 className='title'>{repair.name}</h3>
          <p style={{padding:"20px"}}>{repair.desc}</p>
          <hr></hr>
          <p><FaClock className="clock-icon" /> körülbelül {repair.time/60} óra</p>
          {repair.price && <p style={{marginBottom: "10px", marginTop: "10px"}}><FaMoneyBill className="clock-icon" /> {formatPrice(repair.price)}</p>}
          {!repair.price && <p style={{marginBottom: "10px", marginTop: "10px",  fontSize: "12px"}}>Az árról <a href="tel:+36705559052">telefonon</a> vagy <a href="tel:service@slimstore.hu">e-mailben</a> érdeklődjön!</p>}
        </div>
      ))}
      </div>
      <Link to="/idopontfoglalas"><button className='buttonDefault'>Szervizidőpont foglalása</button></Link>
      </div>)}
      </div>
      </div>
      
  </div>
  );
}

export default Service;
