import React, { useState } from 'react';
import '../styles/Admin.css';
import Service from './admin/Service';

import { jwtDecode } from "jwt-decode";
import PriceList from './admin/PriceList';
import QR from './admin/QR';

const isTokenExpired = (token: string): boolean => {
  try {
    const decoded = jwtDecode(token);
    if (!decoded.exp) {
      // Ha nincs exp mező a tokenben, akkor lejárt
      return true;
    }
    const currentTime = Date.now() / 1000;
    return decoded.exp < currentTime;
  } catch (error) {
    // Hiba esetén kezeljük úgy, mintha a token lejárt volna
    console.error('Invalid token:', error);
    return true;
  }
}
const Admin: React.FC = () => {
    const [menuType, setMenuType] = useState<string>("Szerviz");
    const userRole = localStorage.getItem('userRole');
    const token = localStorage.getItem('token');
    if (userRole !== 'admin' || !token || isTokenExpired(token)) {
         window.location.href = '/login';
    }
  return (
    <div className="home">
      <section className="adminheader">
      </section>
      <div className="admincontainer">
        <button style={{background: menuType === "Szerviz" ? "rgb(254, 184, 9)" : "white"}} className='adminButton' onClick={ () => {setMenuType("Szerviz")}}>Szerviz</button>
        <button style={{background: menuType === "Árlista" ? "rgb(254, 184, 9)" : "white"}} className='adminButton' onClick={ () => {setMenuType("Árlista")}}>Árlista</button>
        <button style={{background: menuType === "QR" ? "rgb(254, 184, 9)" : "white"}} className='adminButton' onClick={ () => {setMenuType("QR")}}>QR kód olvasás</button>
        <button style={{background: menuType === "Raktárkészlet" ? "rgb(254, 184, 9)" : "white"}} className='adminButton' onClick={ () => {setMenuType("Raktárkészlet")}}>Raktárkészlet</button>
        {menuType === "Szerviz" && (<Service /> )}
        {menuType === "Árlista" && (<PriceList /> )}
        {menuType === "QR" && (<QR /> )}
    </div>
  </div>
  );
}

export default Admin;
