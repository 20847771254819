import React from 'react';
import '../styles/Footer.css';
import axios from 'axios';

const Login: React.FC = () => {
    
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        axios.post('https://szerviz.slimstore.hu/api/auth', {
            username: (document.getElementById('username') as HTMLInputElement).value,
            password: (document.getElementById('password') as HTMLInputElement).value
        })
          .then(async response => {
            if (response.data.error) {
                alert('Hibás felhasználónév vagy jelszó!');
                return;
            } else {
                console.log(response.data['role'])
                console.log(response.data['token'])
                response.data['role'] === 'admin' ? localStorage.setItem('userRole', 'admin') : localStorage.setItem('userRole', 'user');
                localStorage.setItem('userRole', response.data['role'] === 'admin' ? 'admin' : 'user');
                localStorage.setItem('token', response.data['token']);
                if (response.data['role'] === 'admin') {
                    window.location.href = '/admin';
                } else {
                    window.location.href = '/home';
                }
            }
          })
          .catch(error => {
            console.error('Error creating appointment:', error);
            alert('Hiba történt az időpontfoglalás során. Kérjük, próbálja újra később!');
          });
      }
  return (
    <div className="home">
      <section className="intro">
        <h1>Bejelentkezés</h1>
      </section>
    <div className="login-container">
        <form id="login-form" onSubmit={handleSubmit}>
            <h2>Bejelentkezés</h2>
            <div className="form-group">
                <label>Felhasználónév:</label>
                <input type="text" id="username" name="username" required></input>
            </div>
            <div className="form-group">
                <label>Jelszó:</label>
                <input type="password" id="password" name="password" required></input>
            </div>
            <button type="submit">Login</button>
        </form>
        <div id="message"></div>
    </div>
    </div>
  );
}

export default Login;
