import React from 'react';
import '../styles/Faq.css';

const Aszf: React.FC = () => {

  return (
    <div className="home">
    <section className="intro">
      <h1>Általános Szerződési Feltételek</h1>
      
    </section>
    
    <div style={{width: "50%", margin: "auto"}}>
    <h1>Slim Store Korlátolt Felelősségű Társaság</h1>
    <div className="contact-info">
        <h2>Szolgáltató Információk</h2>
        <p><strong>Név:</strong> Slim Store Korlátolt Felelősségű Társaság</p>
        <p><strong>Székhely:</strong> 1062 Budapest, Andrássy út 97. 3. em. 19. ajtó</p>
        <p><strong>Képviselő:</strong> Bodnár Richárd Dániel</p>
        <p><strong>Cégjegyzékszám:</strong> 01-09-342248</p>
        <p><strong>Adószám:</strong> 26741109-2-42</p>
        <p><strong>Elérhetőség:</strong></p>
        <p>E-mail: <a href="mailto:info@slimstore.hu">info@slimstore.hu</a></p>
        <p>Telefonszám: +36 70 555 9052</p>
    </div>

    <div className="regulation">
        <h2>Hatályba lépés és érvényesség</h2>
        <p>A jelen ÁSZF 2024. Július 1. napjától határozatlan ideig érvényes és hatályos.</p>
        <p>A Szerviz a jelen Általános Szerződési Feltételek hatálya alatt, a mindenkor hatályos jogszabályi keretek között nyújtja szolgáltatásait. Ez azt jelenti, hogy a szolgáltatások igénybevételekor az ügyfélnek figyelembe kell vennie a jelen ÁSZF rendelkezéseit és a magyar jogszabályokat is.</p>
        <p><strong>Szabályozási hiány:</strong> Amennyiben a jelen ÁSZF-ben valamely kérdés nem került szabályozásra, úgy a 2013. évi V. törvény a Polgári törvénykönyvről (Ptk.), illetve a fogyasztó és a vállalkozás közötti szerződések részletes szabályairól szóló 45/2014. (II. 26.) Korm. rendelet alkalmazandók.</p>
        <p>Az ügyfél a szolgáltatások igénybevételével tudomásul veszi és elfogadja a fentiekben foglaltakat.</p>
        <p>Az ügyfél az őt ért jogsérelem, fogyasztói jogvita orvoslása érdekében békéltető testülethez fordulhat. A Békéltető Testületekről, azok működéséről bővebb információ <a href="https://www.bekeltetes.hu">itt érhető el</a>.</p>
        <p>A területileg illetékes Békéltető Testületekről, azok elérhetőségeiről bővebb információ <a href="https://www.bekeltetes.hu/index.php?id=testuletek">itt érhető el</a>.</p>
    </div>

    <div className="guarantee">
        <h2>Jótállás és Garancia</h2>
        <ul>
       <li>A szerviznek lehetősége van arra, hogy a kártérítési kötelezettség alól mentesüljön, ha bizonyítható, hogy a kár oka az ügyfél felelőssége, vagy olyan esemény következtében keletkezett, amelyen a szerviznek nem volt befolyása. Ebben az esetben az ügyfélnek kell viselnie a károkat.</li>
       <li>A jótállási időszak hossza, valamint a jótállás egyéb feltételei és korlátai a szerződésben lesznek meghatározva. A jótállási időszak alatt a szerviz köteles a hibás termékeket vagy szolgáltatásokat javítani, kicserélni, vagy a fizetett díjakat visszatéríteni, a szerződésben meghatározott módon és időkeretben.</li>
       <li>A hiányos, vagy késedelmes szerviz jegyből eredő kárfelelősségét a vállalkozó kizárja, az ügyfél köteles mindenben a valóságnak megfelelően tájékoztatni a vállalkozót. A valótlan, vagy hiányos szerviz jegyből  következő károk, hátrányok miatt a vállalkozó felelősséget nem vállal. A szerviz jegy tartalmát, teljességét, valóságtartalmát és az adatszolgáltatás időpontját Megbízó köteles bizonyítani.</li>
       <li>Az ügyfél kijavítást vagy a hibás alkatrész kicserélést igényelheti, kivéve, ha a választott jótállási jog teljesítése lehetetlen, vagy ha az a kötelezettnek – másik jótállási igény teljesítésével összehasonlítva – aránytalan többletköltséget eredményez, figyelembe véve a szolgáltatás hibátlan állapotban képviselt értékét, a szerződésszegés súlyát és a jótállási jog teljesítésével a jogosultnak okozott érdeksérelmet.</li>
       <li>Amennyiben az ügyfélnek a jótállással kapcsolatos minőségi kifogását kötelezett nem az ügyfél igényének megfelelően rendezi, az ügyfél jogosult a lakóhelye szerint illetékes megyei (fővárosi) kereskedelmi és iparkamarák által működtetett békéltető testület eljárását is kezdeményezni.</li>
       <li>A Vállalkozás a szolgáltatás elvégzésekor köteles a szerviz jegyet szabályszerűen kitölteni. Vállalkozás bélyegző lenyomatának és a kiállítás során képviseletében eljáró személy aláírásának feltüntetésével hitelesíteni, és az ügyfélnek átadni.</li>
       <li>A javításra általánosságban 90 nap garanciát vállal cégünk. A garancia kizárólag a szerviz által beépített alkatrészekre és azok működésére vonatkozik. A garancia a beépített alkatrész működésére és az összeszerelésre érvényes.</li>
    </ul>
</div>
<div className="guarantee">
<h2>Jótállási és garanciális igények kezelése </h2>
<p>A szerviz a javítási munkálatok befejezése után automatikus e-mail értesítést küld az ügyfélnek az átvétel lehetőségéről, amely tartalmazza az átvétel határidejét. 
  Amennyiben az ügyfél nem veszi át a készüléket a megadott határidőig, a készülék a szerviz tulajdonába kerül és szétszerelésre kerül elektronikai hulladékként történő kezelés céljából. 
  Amennyiben az ügyfél a javítás során további kérdéseket kap a javítás további részleteivel kapcsolatban és erre nem ad választ 30 napon belül, 
  vagy nem veszi át a javított készüléket vagy a megrendelt alkatrészt a értesítéstől számított 30 munkanapon belül, a készülék vagy alkatrész automatikusan és minden további 
  felszólítás és megkeresés nélkül a szerviz tulajdonába kerül, lebontásra. Ez alól kivételt képez, ha az ügyfél előzetesen és dokumentálhatóan tájékoztat a meghosszabbított átvételi szándékáról vagy egy későbbi átvételi dátumot jelöl meg.</p>

  <p>Amennyiben a készüléket vízkár, gőz, pára, ütődés, fizikai elváltozás vagy bármely más külső behatás éri, a garancia nem érvényesíthető. 
Az ilyen jellegű sérülések kívül esnek a garancia hatálya alól, tekintettel arra, hogy ezek többnyire az ügyfél hanyagságából vagy gondatlanságából adódnak. 
Emellett fontos hozzátenni, hogy szervizünk nem képes garanciaidő alatt javítani olyan hibás alkatrészeket, mint például egy törött – beázott kijelző vagy alkatrész, 
mivel a nagykereskedések automatikusan elutasítják ezen sérülések miatt beérkező garanciális igényeket. Szervizünk nem áll módjában vállalni ezen elutasítás okból adódó költségeket és kellemetlenségeket.</p>
   
<p>Amennyiben a készülék vízkár vagy egyéb fizikai sérülést szenved, annak teljes körű gyári állapotba való visszaállítása nem lehetséges. 
A fizikai sérülések és vízkárok következményei kiszámíthatatlanok, és a belőle eredő működési problémákra a garancia nem érvényes. 
Ráadásul, az esetleges javítások vagy beavatkozások során a készülék memóriájában tárolt adatok elveszhetnek. Ezen kívül, ha a készülék megnyitásra vagy javításra kerül, szervizünk nem tudja garantálni az IP68 vagy egyéb vízállósági és porállósági szabványnak való megfelelést.</p>




A készülékben felejtett memóriakártyáért, SIM kártyáért felelősséget nem vállal szervizünk, kivéve, ha az ügyfél előzetesen adatmentést kért. Helytelen képernyőzár/PIN kód megadása, vagy ennek elmulasztása esetén elveszett adatokért felelősséget nem vállalunk, továbbá a tesztelés hiányában beépített alkatrészekre a garancia nem érvényesíthető.
<ul>
<li>Amennyiben a javításra történő felvételkor az ügyfél nem hívja fel a figyelmet minden, esetleg nem látható hibára, az átvételkor ezek a problémák még mindig jelen lehetnek. Fizikai hatás, még ha ez nem is látszik a készülék külsején, nem csak a burkolati elemeket vagy a kijelzőt érintheti. Az esetlegesen sérült egyéb alkatrészek közé tartozhatnak a kamera, átvezető kábelek, csatlakozók, alaplapi elemek és más belső alkatrészek, komponensek. Ha egy olyan mechanikailag sérült készülék kerül javításra, melyen a külső sérülés nem azonnal észrevehető, és a mélyebb problémája csak a javítási folyamat során válik nyilvánvalóvá, az kockázatot jelenthet a készülék további működése szempontjából, sőt, az eszköz teljesen használhatatlanná válhat. Beázott készülék javítása esetén továbbá előfordulhatnak egyéb rejtett hibák, melyekért a szerviz felelősséget nem vállal.</li>
<li>Az utángyártott OLED kijelző és az 1/1 minőségű OLED kijelző esetében előfordulhatnak kisebb eltérések a gyári kijelzőhöz képest. Ha a kijelző a funkcióját teljesíti, képet jelenít meg és rendesen érzékel, akkor további garancia nincs rá. Ez azt jelenti, hogy ha az ügyfél nem elégedett a kijelző minőségével vagy működésével, a garancia nem terjed ki a cserére vagy javításra. Különösen igaz ez azokra az esetekre, amikor az ujjlenyomat-olvasó esetlegesen kevésbé érzékeny, mint a gyári beállítás. Tehát, ha egy ilyen OLED kijelző kerül beépítésre a készülékbe,
figyelembe kell venni, hogy a garancia nem terjed ki a gyári kijelző helyett beépített OLED kijelzőre.</li>
<li>Fizikai behatás – mint ütés vagy erős nyomás – következményeként a kijelző olyan elváltozásokat mutathat, mint lila-fekete vagy zöld színű foltok, elszíneződés, lilás villogás vagy hossz-kereszt irányú csíkok. Fontos megjegyezni, hogy az ilyen elváltozások nem mindig társulnak látható sérülésekkel a kijelző üvegen, mert az ütés vagy nyomás hatására az alatta lévő LCD panel is sérülhet. Az ilyen fizikai behatások által érintett kijelzőknél a garancia érvényét veszti.</li>
<li>	Minden javítás utáni készülék átesik a szervizelés utáni készülék diagnosztikán, és az átadásra került szerviz munkalapján feltüntettük minden  talált hibát és az elvégzett javításokat. A javítást követően a munkalappal, átadjuk a készüléket, amely részletes információkat tartalmaz a diagnózisról és az elvégzett munkáról.</li>
<li> A  garancia kizárólag az eredeti,  aláírt, lebélyegzett és hitelesített munkalap/    garanciajegy dokumentummal érvényesíthető. Amennyiben ez a dokumentum hiányzik, a garancia megvonásra kerül és utólag nem pótolható!</li>

           <li>	Amennyiben az ügyfél törött akkumulátor fedéllel, deformált kerettel vagy egyéb fizikai sérüléssel rendelkező készüléket vesz át a szervizünktől – például kijelzőcsere után – a garancia érvényét veszti, hacsak az ügyfél kifejezetten nem kérte a sérült alkatrészek, akkumulátor fedél cseréjét. Ezzel a szabállyal kapcsolatban kérjük, minden ügyfél legyen körültekintő és gondos.</li>
           <li>	A szervizünk minden esetben tájékoztatást nyújt az ügyfélnek a készülék állapotáról, valamint a garancia érvényességéről. Az ügyfél a készülék átvételével elfogadja a jelenlegi állapotát, és tudomásul veszi, hogy az esetlegesen sérült alkatrészek miatt a garancia érvényét vesztheti.</li>

           <li>A garancia érvényét veszti abban az esetben, ha a készüléket utólag egy másik szerviz szakszerűtlenül szerelte össze vagy azt egy másik szerviz megnyitotta és esetleg hiányosan vagy nem megfelelően szerelte össze vagy szedte szét. Az ilyen esetekben a készülék további károsodásának kockázata nagymértékben megnő, és a szerviz nem tudja garantálni a javítás eredményességét és minőségét. Ezenkívül szervizünk nem vállal felelősséget a más külső szervizben, szakszerűtlenül összeszerelt vagy hiányos készülékekben rejlő potenciális károsodásokért.</li>
<li>Amennyiben az ügyfél saját alkatrészt biztosít a javításhoz, a szerviz a szóban forgó alkatrészekkel, valamint ezek által okozott hibákkal kapcsolatos mindenfajta szavatossági és kártérítési kötelezettséget elutasít. Továbbá szervizünk nem vállal felelősséget a ügyfél által hozott alkatrész által okozott esetleges további hardveres vagy szoftveres hibákért. Ez azt jelenti, hogy amennyiben a beépítést követően probléma adódik a készülék működésével, azt az ügyfél által biztosított alkatrész okozta hibának tekintendő. Ez vonatkozik a hibás működésre, a teljesítménybeli csökkenésre vagy bármely más, a javítás során észlelt rendellenességre. Ügyfeleinknek javasoljuk, hogy minden esetben gondosan mérlegeljék a saját alkatrészek használatának kockázatait a javítás során.</li>
<li>Amennyiben az ügyfél az eredeti gyári alkatrész helyett utángyártott vagy alacsonyabb minőségű alkatrészt hoz, és ez a kívánság a szerviz lapon is fel van tüntetve, a garancia nem érvényes. Az utángyártott vagy nem megfelelő minőségű alkatrészek használata esetén a szerviz nem vállal felelősséget az ebből eredő hibákért és károkért. Ezen alkatrészek nem mindig felelnek meg a gyári előírásoknak, és használatuk komoly kockázatokkal járhat, beleértve a készülék teljesítményének csökkenését, nem megfelelő működést vagy az alkatrész korai meghibásodását. Szervizünk javasolja ügyfeleinek, hogy lehetőség szerint csak eredeti, vagy magas minőségű alkatrészeket kérjenek a javítások során. Az eredeti alkatrészek garantálják a készülék hosszú élettartamát, megbízhatóságát és a gyártó által ígért teljesítményt. Az utángyártott vagy gyenge minőségű alkatrészek használatával kapcsolatos további kérdésekben készséggel állok rendelkezésre.</li>
<li>	Típus és szériahibák esetén a szerviz nem vállal felelősséget. Ilyen hibák olyan rendellenességek, amelyek az adott készülék modellnél vagy egy adott sorozatszámban gyártott készülékeknél mutatkoznak meg, és amelyek általában a gyártási folyamat hibáira vagy a tervezési hibákra vezethetőek vissza. Kérjük, hogy ügyfeleink vegyék figyelembe: a típus- és szériahibák gyakran olyan specifikus hibák, amelyekkel kapcsolatban a szerviz korlátozott beavatkozási lehetőségekkel rendelkezik. Ezeknek a hibáknak a kezelése általában a gyártó vagy a forgalmazó felelőssége. Szervizünkben történő javítás során mindent megteszünk, hogy a hibás készüléket a legjobb képességeink szerint javítsuk, de a típus- és szériahibák miatt bekövetkező további hibákért vagy meghibásodásokért felelősséget nem vállalunk.</li>
<li>Amennyiben a szerviz értesítést küld a garanciális alkatrész beszerzéséről, az ügyfélnek 30 naptári napja áll rendelkezésére a készülék szervizbe történő garanciális elhozatalára és javíttatására. Ha az ügyfél nem érkezik be ezen időintervallum alatt, a garancia automatikusan megszűnik. Az ügyfélnek lehetősége van azonban előzetesen egy specifikus időpontot megjelölni a szervizbe hozatalra ezen 30 napos időtartamon belül. Azonban fontos megjegyezni, hogy amennyiben az ügyfél az előre megjelölt időpontban nem jelenik meg, a garancia nem újul meg az új időponttól kezdődően.</li>
</ul>
</div>
<h2>Záradékok (ÁSZF)</h2>

<p>A Slim Store szervizében végezett javításokkal kapcsolatban az ügyfélnek jogában áll a szerviz munkalapon rögzített információk ellenőrzése és elfogadása, ezzel biztosítva a szerviz által végzett munka transzparenciáját. A jótállási idő alatt felmerülő további hibák esetén kérem, hogy időben vegyék fel a kapcsolatot a szervizzel a probléma pontos diagnosztizálása és megoldása érdekében.</p>
<p>Amennyiben a készüléket postai úton szeretnék visszaküldeni garanciális ügyintézés céljából, kérjük, hogy előzetesen vegyék fel a kapcsolatot a szervizes kollégákkal a szükséges lépések egyeztetése érdekében. Amennyiben bármilyen általunk elvégzett javításokkal kapcsolatos bármilyen kérdés merülne fel, aggály esetén kérjük, lépj velünk kapcsolatba.</p>
</div>
  </div>
  );
}

export default Aszf;
