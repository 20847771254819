import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Service from './components/Service';
import CheckStatus from './components/CheckStatus';
import Aszf from './components/Aszf';
import Booking from './components/Booking';
import Contact from './components/Contact';
import Login from './components/Login';
import Admin from './components/Admin';

const App: React.FC = () => {
  return (
    
    <BrowserRouter>
      <div className="app">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/idopontfoglalas" element={<Booking />} />
          <Route path="/szerviz" element={<Service />} />
          <Route path="/szervizallapot" element={<CheckStatus />} />
          <Route path="/aszf" element={<Aszf />} />
          <Route path="/kapcsolat" element={<Contact />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
