import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';
import { FaCaretLeft } from 'react-icons/fa';
const Header: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const userRole = localStorage.getItem('userRole');
  const token = localStorage.getItem('token');

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const handleLogout = () => {
    localStorage.clear();
    setMenuOpen(false);
     window.location.href = '/home';
};

  return (
    <header className="header">
      <a href='https://slimstore.hu'><div className="backtoStore"><FaCaretLeft className='back'/> <p className='backTitle'>Slim Store</p></div></a>
      <div className="logo">
        <Link to="/"><img style={{maxHeight:"50px"}} src='https://szerviz.slimstore.hu/slimservice_logo_ok.png' alt="Slim Service Logo" /></Link>
      </div>
      <div className="menu-icon" onClick={toggleMenu}>
        ☰
      </div>
      <nav className={`navigation ${menuOpen ? 'open' : ''}`}>
      <Link to="/idopontfoglalas" onClick={() => setMenuOpen(false)}>Szervizidőpont foglalás</Link>
      <Link to="/szervizallapot" onClick={() => setMenuOpen(false)}>Szervizállapot ellenőrzés</Link>
        <Link to="/szerviz" onClick={() => setMenuOpen(false)}>Árak</Link>
        <Link to="/kapcsolat" onClick={() => setMenuOpen(false)}>Kapcsolat</Link>
        <Link to="/aszf" onClick={() => setMenuOpen(false)}>ÁSZF</Link>
        <Link to="https://slimstore.hu/" onClick={() => setMenuOpen(false)}>Slim Store</Link>
        {userRole === 'admin' && token ? <Link to="/admin" onClick={() => setMenuOpen(false)}>Admin</Link> : null}
        {userRole === 'admin' && token ? <Link to="/logout" onClick={handleLogout}>Kijelentkezés</Link> : null}
      </nav>
    </header>
  );
}

export default Header;