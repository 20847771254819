import React, {useState} from 'react';
import '../styles/About.css';
import Lottie from 'lottie-react';
import axios from 'axios';
import { FaInfoCircle } from 'react-icons/fa';

interface Status {
  manufacturer: string;
  model: string;
  status: string;
}
const CheckService: React.FC = () => {
  const [status, setStatus] = useState<Status | null>(null);
  const [imei, setImei] = useState<string>('');

  const getStatus = async () => {
    axios.get(`https://szerviz.slimstore.hu/api/bookingstatus?imei=${imei}`)
        .then(response => {
          console.log(response)
            if (response.data.length > 0) {
              setStatus(response.data[0])
            } else {
              window.alert('Nem található ilyen IMEI számú készülék az adatbázisban')
            }
        })}
  const getStatusLocalizedString = (status: string) => {
    switch (status) {
      case 'under_review':
        return 'Ellenőrzés alatt';
      case 'accepted':
        return 'Garanciális javítás elfogadva';
      case 'rejected':
        return 'Garanciális javítás elutasítva';
      case 'awaiting_arrival':
        return 'Várakozás a termék érkezésére';
      case 'under_repair':
        return 'Javítás alatt';
      case 'ready':
        return 'Kész';
      default:
        return 'Ismeretlen';
    }
  }
  return (
    <div className="home">
      <section className="intro">
        <h1>Szervizállapot ellenőrzés</h1>
      </section>
      
      
    <div style={{ maxWidth: "500px", margin: "auto", textAlign: "center", padding: "25px"}}>
    <div className="tooltip"><FaInfoCircle className="info-icon" /><span className="tooltiptext">
            Az IMEI (International Mobile Equipment Identity) egy egyedi azonosító szám, amely minden mobiltelefonhoz tartozik. 
            Ezt az azonosítót a hálózati szolgáltatók használják a készülékek azonosítására. 
            Az IMEI számot a telefon hátlapján, az akkumulátor alatt, vagy a telefon beállításaiban találod meg. 
            Ezen kívül, ha beüti a *#06# kódot a telefon hívóképernyőjén, szintén megjelenik az IMEI szám.
        </span></div>
      <h3>Írja be az IMEI számát!</h3>
    <input style={{textAlign: "center"}} type="text" placeholder="IMEI szám" value={imei} onChange={(e) => setImei(e.target.value)}></input>
    <button style={{marginTop: "15px"}} className='buttonDefault' onClick={getStatus}>Keresés</button>
    {status ? <div className='statusblock'>
      <h2>{status.manufacturer} {status.model}</h2>
      <p>{getStatusLocalizedString(status.status)}</p>
    </div> : null}
        <Lottie  animationData={require('../assets/searchphone.json')} />
  </div>
  
    </div>
  );
}

export default CheckService;
